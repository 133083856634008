.content {
  flex-direction: column;
  display: flex;

  .company-panel {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .company-title {
      background-image: url("../../../../assets/img/event-bg.svg");
      background-size: cover;
      color: white;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      img {
        max-height: 150px;
      }
    }

    .MuiCardContent-root {
      padding: 20px !important;
      display: flex;
      flex: 1 1 0;
      flex-direction: row;

      .infos-panel {
        display: flex;
        flex-direction: column;

        .section-title {
          margin-bottom: 10px;
        }

        &.left {
          flex: 0.35 1 0;
          margin-right: 30px;

          .infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        &.middle {
          flex: 0.35 1 0;
          margin-right: 30px;

          .infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
          }
        }

        &.right {
          flex: 0.3 1 0;
          background: #F8F7F7;
          display: flex;
          flex-direction: column;
          padding: 20px;

          .edit {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }

          .infos {
            flex: 1 1 0;
            justify-content: center;
          }

        }
      }
    }
  }
}

.MuiCardContent-root {
  padding: 20px !important;
  display: flex;
  flex: 1 1 0;
  flex-direction: row;

  .infos-panel {
    display: flex;
    flex-direction: column;

    .section-title {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.left {
      flex: 0.35 1 0;
      margin-right: 30px;

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &.middle {
      flex: 0.35 1 0;
      margin-right: 30px;

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &.right {
      flex: 0.3 1 0;
      background: #F8F7F7;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .edit {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .infos {
        flex: 1 1 0;
        justify-content: center;
      }

    }
  }
}


