.app-container {
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .content {
    //flex: 0.9 1 0;
    padding: 20px;
  }
}
