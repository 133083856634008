
.filter-menu {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0.8 1 0;
  }

  .add-button {
    justify-self: end;
    flex: 0.2 1 0;
  }
}