.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .section {
    .add-event-card {
      margin-bottom: 10px;
    }

    .event-card:not(:last-child) {
      margin-bottom: 10px;
    }

    .comment-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

  }

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    img {
      max-height: 150px;
    }
  }

  .buttons-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .qr-code-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px;

    .qr-code {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 100px;
        width: 100px;
      }
    }
  }

}

.event {
  display: flex;
  flex: 1 1 0;
  flex-direction: row;

  .infos-panel {
    display: flex;
    flex-direction: column;

    .section {
      .section-title {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }

      .section-info {
        color: hsl(0,0%,50%);
        font-size: small;
        font-style: italic;
        align-content: right;
        margin-bottom: 10px; 
      }
    }

    &.left {
      flex: 0.6 1 0;
      margin-right: 30px;

      .error {
        color: #f44336;
        font-size: smaller;
      }
    }

    &.right {
      flex: 0.4 1 0;
      background: #F8F7F7;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    
        img {
          max-height: 150px;
        }        
      }

      .buttons-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #D5D5D5;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .qr-code-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #D5D5D5;
        padding-top: 10px;
        padding-bottom: 10px;
    
        .qr-code {
          display: flex;
          flex-direction: column;
          align-items: center;
    
          img {
            height: 100px;
            width: 100px;
          }
        }
      }

      .edit {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .infos {
        flex: 1 1 0;
      }

      .send-invitation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #D5D5D5;
        padding-top: 20px;
        margin-top: 20px;

        .button-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 90%;
        }
      }
    }
  }
}