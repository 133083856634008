.float-left {
  float: left;
}

.float-right {
  float: right;
}

.relative-position {
  position: relative;
}

.fixed,
.fullscreen,
.fixed-center,
.fixed-bottom,
.fixed-left,
.fixed-right,
.fixed-top,
.fixed-top-left,
.fixed-top-right,
.fixed-bottom-left,
.fixed-bottom-right {
  position: fixed;
}

.absolute,
.absolute-full,
.absolute-center,
.absolute-bottom,
.absolute-left,
.absolute-right,
.absolute-top,
.absolute-top-left,
.absolute-top-right,
.absolute-bottom-left,
.absolute-bottom-right {
  position: absolute;
}

.fixed-top,
.absolute-top {
  top: 0;
  left: 0;
  right: 0;
}

.fixed-right,
.absolute-right {
  top: 0;
  right: 0;
  bottom: 0;
}

.fixed-bottom,
.absolute-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed-left,
.absolute-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.fixed-top-left,
.absolute-top-left {
  top: 0;
  left: 0;
}

.fixed-top-right,
.absolute-top-right {
  top: 0;
  right: 0;
}

.fixed-bottom-left,
.absolute-bottom-left {
  bottom: 0;
  left: 0;
}

.fixed-bottom-right,
.absolute-bottom-right {
  bottom: 0;
  right: 0;
}

.fullscreen {
  z-index: 6000;
  border-radius: 0 !important;
  max-width: 100vw;
  max-height: 100vh;
}

.absolute-full,
.fullscreen {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed-center,
.absolute-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vertical-top {
  vertical-align: top !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.on-left {
  margin-right: 12px;
}

.on-right {
  margin-left: 12px;
}

$sides: (top, bottom, left, right);

@for $i from 1 through 10 {
  $calc: $i*5;

  .margin-#{$calc} {
    margin: #{$calc}px !important;
  }

  .padding-#{$calc} {
    padding: #{$calc}px !important;
  }

  @each $side in $sides {
    .margin-#{str-slice($side, 0, 1)}-#{$calc} {
      margin-#{$side}: #{$calc}px !important;
    }

    .padding-#{str-slice($side, 0, 1)}-#{$calc} {
      padding-#{$side}: #{$calc}px !important;
    }
  }

  .margin-top-bottom-#{$calc} {
    margin-top: #{$calc}px !important;
    margin-bottom: #{$calc}px !important;
  }

  .margin-left-right-#{$calc} {
    margin-left: #{$calc}px !important;
    margin-right: #{$calc}px !important;
  }

  .padding-top-bottom-#{$calc} {
    padding-top: #{$calc}px !important;
    padding-bottom: #{$calc}px !important;
  }

  .padding-left-right-#{$calc} {
    padding-left: #{$calc}px !important;
    padding-right: #{$calc}px !important;
  }
}


@for $i from 1 through 10 {
  .margin-#{$i * 5} {
    margin: 5px * $i !important;
  }
  .margin-top-#{$i * 5} {
    margin-top: 5px * $i !important;
  }
  .margin-bottom-#{$i * 5} {
    margin-bottom: 5px * $i !important;
  }
  .margin-top-bottom-#{$i * 5} {
    margin-top: 5px * $i !important;
    margin-bottom: 5px * $i !important;
  }
  .margin-left-#{$i * 5} {
    margin-left: 5px * $i !important;
  }
  .margin-right-#{$i * 5} {
    margin-right: 5px * $i !important;
  }
  .margin-left-right-#{$i * 5} {
    margin-left: 5px * $i !important;
    margin-right: 5px * $i !important;
  }
  .padding-#{$i * 5} {
    padding: 5px * $i !important;
  }
  .padding-top-#{$i * 5} {
    padding-top: 5px * $i !important;
  }
  .padding-bottom-#{$i * 5} {
    padding-bottom: 5px * $i !important;
  }
  .padding-top-bottom-#{$i * 5} {
    padding-top: 5px * $i !important;
    padding-bottom: 5px * $i !important;
  }
  .padding-left-#{$i * 5} {
    padding-left: 5px * $i !important;
  }
  .padding-right-#{$i * 5} {
    padding-right: 5px * $i !important;
  }
  .padding-left-right-#{$i * 5} {
    padding-left: 5px * $i !important;
    padding-right: 5px * $i !important;
  }
}

.width-full {
  width: 100% !important;
}

.width-half {
  width: 50% !important;
}

.height-full {
  height: 100% !important;
}

.height-half {
  height: 50% !important;
}
