.content {
  flex-direction: column;
  display: flex;

  a {
    color: #C5326E;
  }

  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-bottom: 10px;
  }

  .action-link {
    .MuiButton-label {
      text-decoration: underline;
    }
  }

  

  .event-panel {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .event-title {
      background-image: url("../../../assets/img/event-bg.svg");
      background-size: cover;
      color: white;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .MuiCardContent-root {
      padding: 30px !important;
      display: flex;
      flex: 1 1 0;
      flex-direction: row;

      .infos-panel {
        display: flex;
        flex-direction: column;

        .section-title {
          margin-bottom: 10px;
        }

        &.left {
          flex: 0.6 1 0;
          margin-right: 30px;

          .error {
            color: #f44336;
            font-size: smaller;
          }
        }

        &.right {
          flex: 0.4 1 0;
          background: #F8F7F7;
          display: flex;
          flex-direction: column;
          padding: 20px;

          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        
            img {
              max-height: 150px;
            }
          }

          .edit {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }

          .infos {
            flex: 1 1 0;
          }

          .send-invitation {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #D5D5D5;
            padding-top: 20px;
            margin-top: 20px;

            .button-container {
              display: flex;
              justify-content: center;
              flex-direction: column;
              width: 90%;
            }
          }
        }
      }
    }
  }
}

.table-container {
  max-height: 52vh;

  .sent {
    color: #459536;
  }

  .not-sent {
    color: #FF862E;
  }

  .MuiTableCell-stickyHeader {
    background: #FFFFFF;
  }

  
}
.border {
  border: 1px solid #D5D5D5;
}
.p-1 {
  padding: 1rem 1rem 0rem 1rem;
}
.pt-1 {
  padding: 1rem 1rem 0rem 1rem;
}
.pr-l-1 {
  padding: 0rem 1rem 0rem 1rem;
}
.center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notif {
  display: flex;
  flex-direction: column;
  border: 1px solid #D5D5D5;
  padding: 10px;
}