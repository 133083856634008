.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    max-height: 200px;
  }

  .welcome {
    padding: 20px;
  }
}
