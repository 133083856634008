.companies-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: row;
}
.title-event {
  display: flex;
  flex-shrink: 0;
}
.separator {
  display: flex;
  flex: 1;
}
.h-100 {
  height: 100%
}
.mt-2 {
  margin-top: 1rem;
}
/*.MuiTableCell-stickyHeader {
  text-align: center !important;
  width: 33.33%;
}
.MuiTableCell-body {
  text-align: center !important;
}*/
.selected:hover {
  cursor: pointer;
}
.mt-1 {
  margin-top: 1rem;
}
.padding-content {
  padding: 0rem !important;
}

.MuiButton-colorInherit {
  background-color: white !important;
}
.pl-2 {
  padding-left: 2rem;
}
.section-info {
  color: hsl(0,0%,50%);
  font-size: small;
  font-style: italic;
  align-content: right;
  margin-bottom: 20px; 
}