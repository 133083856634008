body {
  margin : 0;
  min-height:100vh;
  font-family: Helvetica, Arial, serif;
  font-size: .950rem;

  #root, .App, main {
    min-height:100vh;
    min-width: 100vh;
  }

  .App {
    background: #F1EEF9;
  }
}

@font-face {
  font-family: 'NunitoBold';
  src: url('../assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype')
}
