$secondary:#CC2E63;

.company-form__field {
    margin-top: 2%;
    margin-bottom: 2%;
}

.company-form--width-all {
    width: 100%;
}

.company-form__bottom {
    margin-top: 5%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.MuiButton-contained {
    &.company-form__primary-button {
        width: 47%;
        text-transform: none;
        padding-top: 2%;
        padding-bottom: 2%;
        background-color: $secondary;
    }

    &.company-form__secondary-button {
        width: 47%;
        background-color: white;
        color: $secondary;
        padding-top: 2%;
        padding-bottom: 2%;
        cursor: pointer;
        text-transform: none;
        box-shadow: none;
    }
}

.section-title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    img {
      margin-right: 10px;
    }
  }