$primary: #CC2E63;

.color-primary {
  color: #DA408A;
}

.color-second {
  color: #0076C6;
}

.text-bold {
  font-weight: bold !important;
}

.text-small {
  font-size: small;
}

.text-smaller {
  font-size: smaller;
}

.text-italic {
  font-style: italic;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
