.content {
  flex-direction: column;
  display: flex;

  .account-panel {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .account-title {
      background-image: url("../../../assets/img/event-bg.svg");
      background-size: cover;
      color: white;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      img {
        max-height: 150px;
      }
    }

    .MuiCardContent-root {
      padding: 30px !important;
      display: flex;
      flex: 1 1 0;
      flex-direction: row;

      .infos-panel {
        display: flex;
        flex-direction: column;

        .section-title {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 10px;
          }
        }

        &.left {
          flex: 0.6 1 0;
          margin-right: 30px;

          .infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        &.right {
          flex: 0.4 1 0;
          background: #F8F7F7;
          display: flex;
          flex-direction: column;
          padding: 20px;

          .login {
            display: flex;
            flex-direction: column;
            flex: 0.8 1 0;
            justify-content: space-around;
  
            .inputs {
              display: flex;
              flex-direction: column;
            }
  
            .error {
              color: #f44336;
              font-size: smaller;
            }
        }

        }
      }
    }
  }
}


