.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //flex: 0.1 1 0;
  max-width: 140px;

  background: white;

  -webkit-box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.16);

  .logo {
    padding: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .MuiButton-text {
    width: 100%;
    padding: 16px;
    flex-direction: column;
    font-size: x-small;
    color: black;

    img {
      height: 30px;
      width: 30px;
    }
  }

  .nav {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;

    a {
      text-decoration: none;
      color: black;

      &:visited {
        text-decoration: none;
        color: black;
      }

      &:hover {
        text-decoration: none;
        color: black;
      }

      &:focus {
        text-decoration: none;
        color: black;
      }

      &:hover {
        text-decoration: none;
        color: black;
      }

      &:active {
        text-decoration: none;
        color: black;
      }

      &.active-item {
        background: #FAFAFA;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
        border-left: #C5326E 5px solid !important;

        .MuiButton-text {
          padding-left: 11px;

          .label {
            color: #C5326E !important;
          }
        }
      }
    }

  }

  .logout {
    .menu-item {
      width: 100%;
    }
  }


}
