.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > div:not(.title) {
    margin-left: 15px;
  }

  .filter-date {
    width: 150px;
  }

  .filter-country {
    width: 200px;
  }

  .filter-event {
    width: 250px;
  }
}
