.companies-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: row;
}
.title-event {
  display: flex;
  flex-shrink: 0;
}
.separator {
  display: flex;
  flex: 1;
}
.h-100 {
  height: 100%
}
.mt-2 {
  margin-top: 1rem;
}

.selected:hover {
  cursor: pointer;
}
.mt-1 {
  margin-top: 1rem;
}
.padding-content {
  padding: 0rem !important;
}

.MuiButton-colorInherit {
  background-color: white !important;
}
.pl-2 {
  padding-left: 2rem;
}

.params {
  display: flex;
  flex-direction: column;
  border: 1px solid #D5D5D5;
  padding: 10px;
}

.buttons {
  display: flex;
  padding-top: 8px;
  align-items: center;
}