.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-box-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-outline {
  outline: 0 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis-2-lines,
.ellipsis-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-2-lines {
  -webkit-line-clamp: 2;
}

.ellipsis-3-lines {
  -webkit-line-clamp: 3;
}

.readonly {
  cursor: default !important;
}

.disabled,
[disabled],
.disabled *,
[disabled] * {
  cursor: not-allowed !important;
}

.disabled,
[disabled] {
  opacity: 0.6 !important;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.transparent {
  background: transparent !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden-y {
  overflow-y: hidden !important;
}
