.MuiCardContent-root {

  .number {
    font-size: 0.875rem;
    font-family: NunitoBold, sans-serif;
  }

  .sub-text {
    font-size: 0.75rem;
  }

  .futura {
    font-family: Futura, sans-serif;
  }

  .section {
    .section-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;      

      img {
        margin-right: 10px;
      }
    }

    .section-info {
      color: hsl(0,0%,50%);
      font-size: small;
      font-style: italic;
      align-content: right;
      margin-bottom: 10px; 
    }
  }
}
