.login-card {
  width: 90vh;
  height: 55vh;
  display: flex;
  flex-direction: row;

  .panel {
    display: flex;

    &-left {
      flex: 0.4 1 0;
      background-image: url("../../../assets/img/grey-bg.svg");
      display: flex;
      justify-content: center;
      align-content: center;

      img {
        width: 80%;
      }
    }

    &-right {
      flex: 0.6 1 0;
      display: flex;
      flex-direction: column;
      padding: 20px 40px;

      .title {
        flex: 0.2 1 0;
      }

      .login {
          display: flex;
          flex-direction: column;
          flex: 0.8 1 0;
          justify-content: space-around;

          .inputs {
            display: flex;
            flex-direction: column;
          }

          .error {
            color: #f44336;
            font-size: smaller;
          }

          .action-link {
            .MuiButton-label {
              text-decoration: underline;
            }
          }
      }
    }
  }
}
